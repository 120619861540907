import useEmpresa, { MODULO_MEU_WHATSAPP } from '@/contexts/useEmpresa'
import clipboard from 'clipboardy'
import { toast } from 'react-toastify'
import './PixModalContent.scss'
import type { PagamentoPix } from '@/types/pagamento'

interface PixModalContentProps {
    onClose: () => void
    pixModal: PagamentoPix
}

export default function PixModalContent({pixModal, onClose}: PixModalContentProps) {
    const { empresa } = useEmpresa()

    return <div className='pix-modal-content'>
        <div className='pix-modal-info'>
            <img src={pixModal.qrcode} className='pix-modal-qrcode' alt="QrCode" />
            <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
                <h3 style={{ margin: '18px 0 0 0' }}>Pagamento com Pix</h3>
                {/* <div>
                    <p style={{ marginTop: 0 }}><strong>Conta Destino:</strong><br />ENCANTU SOLUÇÕES EM SISTEMAS LTDA</p>
                    <p style={{ fontSize: 12, marginBottom: 0 }}><strong>Instituição Bancária:</strong> FLAGSHIP IP LTDA</p>
                </div> */}
                <p style={{margin: 0, color: '#e52d2d', fontSize: 12}}>Após o registro do pagamento iremos enviar uma mensagem whatsapp para o número informado que a fatura foi paga! O número que irá enviar a mensagem é {empresa?.ModuloMeuWhatsapp && empresa?.Modulos?.includes(MODULO_MEU_WHATSAPP) ? empresa.Telefone : '(48) 9 8865-4484'}</p>
                <input title="Código copia e cola" disabled readOnly style={{ width: '100%', borderRadius: 8, padding: '8px 12px', border: '1px solid #b8b8b8' }} type="text" value={pixModal.emv ?? ''} />
                <button
                    type='button'
                    onClick={() => clipboard
                        .write(pixModal.emv)
                        .then(() => toast.success('Copiado com sucesso', { autoClose: 2000 }))
                    }
                    style={{ width: '100%' }}
                    className="btn btn-primary"
                >
                    Copiar código Pix
                </button>
            </div>
        </div>
        <div style={{ display: 'flex', marginTop: 12 }}>
            <button type='button' onClick={onClose} style={{ flex: 1 }} className="btn btn-danger">
                Fechar
            </button>
        </div>
    </div>
}
