import { CardBody, CardHeader, CardTitle, CardToolbar } from "@/components/Card"
import FormGroup from "@/components/FormGroup"
import Loading from "@/components/Loading"
import { useTranslation } from 'react-i18next'

export default function InfoPessoais({user, loading, onSubmit, register}) {
    const { t } = useTranslation()

    return <>
        <CardHeader>
            <CardTitle>{t('Informações pessoais')}</CardTitle>
            <CardToolbar>
                <button
                    type="submit"
                    form="basic-info-form"
                    className="btn btn-primary"
                    disabled={loading}
                >
                    {loading && <Loading type="white" />}
                    {t('Salvar Alterações')}
                </button>
            </CardToolbar>
        </CardHeader>
        <CardBody>
            <form id="basic-info-form" onSubmit={onSubmit}>
                <FormGroup inline name="Nome" value={user.Nome} register={register} />
                <FormGroup inline name="RG" value={user.RG} register={register} required={false} />
                <FormGroup inline name="CPF" value={user.CPF} register={register} />
                <FormGroup
                    inline
                    required={false}
                    name="EstadoCivil"
                    label="Estado Civil"
                    value={user.EstadoCivil}
                    register={register}
                />
                <FormGroup inline name="Nascimento" type="date" value={user.Nascimento} register={register} />
                <FormGroup inline name="Profissao" label="Profissão" value={user.Profissao} register={register} required={false} />
                <div className="separator separator-dashed"></div>
                <FormGroup inline name="Email" type="email" value={user.Email} register={register} />
                <FormGroup inline name="Telefone" type="phone" value={user.Telefone} register={register} />
                <div className="form-group-full">
                    <div className="form-label"></div>
                    <div className='form-control' style={{ height: 'auto' }}>
                        <input type="checkbox" id="politicaPrivacidade" {...register('politicaPrivacidade')} />
                        <label htmlFor="politicaPrivacidade"> {t('Li e concordo com as')} <a href="https://sistemapet.com/politicaprivacidade.html" target="_blank" rel="noreferrer">{t('Políticas de Privacidade')}</a></label>
                    </div>
                </div>
            </form>
        </CardBody>
    </>
}
